import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "Extrusion Molding",
  "description": "For manufacturing long, symmetric metal parts.",
  "author": "Orkun Zafer Ozturk",
  "categories": ["mechanical"],
  "date": "2022-01-01T00:00:00.000Z",
  "featured": false,
  "tags": ["", "", "", "", ""],
  "relatedArticles": ["Blow-Molding", ""]
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`Extrusion is the process of creating long symmetric shapes out of molten material. Mostly, aluminum and its alloys are used for the final product since they have a lower melting point than other metals and can be shaped easily. This starting material comes in the shape of a billet. `}</p>
    <p>{`The metal extrusion process involves a ram, a sealing plate, and a die. The location of the die is based on the type of extrusion method used, which are:`}</p>
    <ol>
      <li parentName="ol">{`Direct Metal Extrusion`}</li>
      <li parentName="ol">{`Indirect Metal Extrusion`}</li>
    </ol>
    <h2 {...{
      "id": "direct-metal-extrusion"
    }}>{`Direct Metal Extrusion`}</h2>
    <p>{`In direct metal extrusion, the ram pushes the billet through a stationary die. With this method, the direction of the extruded part is in the same direction as the ram. The ram pushes the metal out across the stationary die to create a continuous shape. In this process, the flow of the metal output is in the same direction as the direction of the ram movement. `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.87644787644788%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABtklEQVQoz4WSzWoTYRiF5yrciBShC1fegTuvw6VbV641YEHceANSSKOlNMT84KSNtmWibRX8K5QulCTzk2aaSZOYycwkk5nMPJKJTcaWkgOH74Pvfc/3/hxBlSsYTR3D0GmcqtQ1mdO6guPYeL7PaDSK6Hne7LyOrusi2HYfdziIOHBsHMeK6AcBIXPE79fB932ERUG9bgfXsQm8EUq1Qjj2px+E4YxXBC8eJlVNUKidca/wjZUjlZbZp2HaNPs2VeOcrjOcJMxygiCIhK4IxlHU2tzNfUd48opbKYmbqTJLr8vcWD/kgXTyX6zreVRV7VLLYUh7HNLqmei6zp+WgdLQebzynMJ2icL7HTbFEukPEsWPh+ztf2bv4Asl6RNb0gHHvyqRjDefYYhh2dQ0DVVVsTptZP2MR0+fkc3nyYlFUum3rG5mWUvneJPORlzbyJB48ZKNTD5SGXnefCkto4ksKwTDAWK9zZ3MV4TEKsvJHW4nd1lO7rK0vs/D8rTl8b8x1WQFcWt7WuGF4OUZvpOb3Bd/kPip8LvncNyxOOlaHJ2b1Ex7sW3iGwvCxY6LWyZunYngX9dA4X6fuDvIAAAAAElFTkSuQmCC')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/df11c1d8c959e3e45a44e4813a7b0724/652f5/extrusion-molding-01.webp 259w", "/static/df11c1d8c959e3e45a44e4813a7b0724/c29d2/extrusion-molding-01.webp 518w", "/static/df11c1d8c959e3e45a44e4813a7b0724/77d2c/extrusion-molding-01.webp 1035w", "/static/df11c1d8c959e3e45a44e4813a7b0724/ba7d2/extrusion-molding-01.webp 1553w", "/static/df11c1d8c959e3e45a44e4813a7b0724/882b9/extrusion-molding-01.webp 1920w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/df11c1d8c959e3e45a44e4813a7b0724/a2ead/extrusion-molding-01.png 259w", "/static/df11c1d8c959e3e45a44e4813a7b0724/6b9fd/extrusion-molding-01.png 518w", "/static/df11c1d8c959e3e45a44e4813a7b0724/e3189/extrusion-molding-01.png 1035w", "/static/df11c1d8c959e3e45a44e4813a7b0724/44d59/extrusion-molding-01.png 1553w", "/static/df11c1d8c959e3e45a44e4813a7b0724/29114/extrusion-molding-01.png 1920w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/df11c1d8c959e3e45a44e4813a7b0724/e3189/extrusion-molding-01.png",
            "alt": "Direct metal extrusion",
            "title": "Direct metal extrusion",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span>{`  `}</p>
    <h2 {...{
      "id": "indirect-metal-extrusion"
    }}>{`Indirect Metal Extrusion`}</h2>
    <p>{`In indirect metal extrusion, the process is similar to pressing a cookie cutter into a dough. This way, the billet starts off in the sealing plate, and a ram with a die attached to it pushes into the sealing plate, releasing the extruded shape towards the opposite direction.   `}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1035px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "47.87644787644788%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7DAAAOwwHHb6hkAAABwElEQVQoz3WRzWsTURTFs3HnfyBdiIK6EFduxf/Jpe5cFgpqFVsbEypaaotaSt1YJBVDhmoEAxE3aprMTCYz+SB5eZmPzHs/ydQ0iW0OHO7lvXfOfffelFmrYJlHeK6DUzexrSq16h+cukUURYRhOJfj+yAITmKqL3qM6PsSKQX+QNIXXQayzwhag+aYSmuU5oSaWQyHQ1L/nWGaJr4fJHmsVBJF04Uw4Cw0XJdSqYQQAqXUsaFOKuskSikJAn8ikAGfqg0KpkexKfjidvnZ6nJgNYk01G2bcrlMHMeTH46MplFzXHLfSqAV2R9VLm7kubFV4HJ2n0vrH7nw4oCrWwaukHNa1ho7ijGbLeqWyeeCwUp2neW1DE/WnrOSzvDo6SpLi4vkcjnOp/dZ2DQQQTgzmhnDjpTUbJuW55E//Mr9B495s7vHs+0d7mU2uJt+yZ2lZR6+e8+51Q8svDbohVEiV/86nLQMuI5DpVJJZtjuCcq/j5JHr341uPK2yM2971zfNri2mef2ziG3dou0/XCOodZnbnC8LK/Vot3pEA1jYq2J4hizNyBWmmn9jGEiVupUPoLnujQ9b1zmVNFpw78AruTQkBKBuQAAAABJRU5ErkJggg==')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<picture parentName="span">{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/48a763b9e9f9d31974d73709d494b292/652f5/extrusion-molding-02.webp 259w", "/static/48a763b9e9f9d31974d73709d494b292/c29d2/extrusion-molding-02.webp 518w", "/static/48a763b9e9f9d31974d73709d494b292/77d2c/extrusion-molding-02.webp 1035w", "/static/48a763b9e9f9d31974d73709d494b292/ba7d2/extrusion-molding-02.webp 1553w", "/static/48a763b9e9f9d31974d73709d494b292/882b9/extrusion-molding-02.webp 1920w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/webp"
          }}></source>{`
          `}<source parentName="picture" {...{
            "srcSet": ["/static/48a763b9e9f9d31974d73709d494b292/a2ead/extrusion-molding-02.png 259w", "/static/48a763b9e9f9d31974d73709d494b292/6b9fd/extrusion-molding-02.png 518w", "/static/48a763b9e9f9d31974d73709d494b292/e3189/extrusion-molding-02.png 1035w", "/static/48a763b9e9f9d31974d73709d494b292/44d59/extrusion-molding-02.png 1553w", "/static/48a763b9e9f9d31974d73709d494b292/29114/extrusion-molding-02.png 1920w"],
            "sizes": "(max-width: 1035px) 100vw, 1035px",
            "type": "image/png"
          }}></source>{`
          `}<img parentName="picture" {...{
            "className": "gatsby-resp-image-image",
            "src": "/static/48a763b9e9f9d31974d73709d494b292/e3189/extrusion-molding-02.png",
            "alt": "Indirect metal extrusion",
            "title": "Indirect metal extrusion",
            "loading": "eager",
            "decoding": "async",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            }
          }}></img>{`
        `}</picture>{`
    `}</span></p>
    <p>{`There's a less drastic temperature change with indirect metal extrusion, which is something you want for uniformity, better tensile strength, and uniform grain. But material handling is more sensitive to impurities. This requires some billet surfaces to be shaved off, which requires more aluminum overall.  `}</p>
    <p>{`Extruded aluminum parts are mostly used in probes, mounting poles, and other uniform, long, and circular parts of IoT hardware products.   `}</p>
    <p>{`We do not currently support extrusion molding for plastics and polymers.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      